import logo from "../../images/logo.svg";
import smile from "../../images/smile.svg";
import heart from "../../images/heart.svg";
import dishdouble from "../../images/dishdouble.webp";
import partner1 from "../../images/partner1.jpg";
import partner3 from "../../images/partner3.png";
import review1 from "../../images/reviews/review1.jpg";
import review2 from "../../images/reviews/review2.jpg";
import review3 from "../../images/reviews/review3.jpg";
import review4 from "../../images/reviews/review4.jpg";
import review5 from "../../images/reviews/review5.jpg";
import review6 from "../../images/reviews/review6.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from "react";
// import axios from "axios";
import { Autoplay } from "swiper";
import main from "./TestMainPage.module.css";
import SliderCategory from "../../Components/SliderCategory/SliderCategory";
import { useGetAllCategoriesQuery } from "../../app/store";
import { Link } from "react-router-dom";
import FAQ from "../../Components/FAQ/FAQ";
// import ModalInfo from "../../Components/ModalInfo/ModalInfo";
import { useTranslation } from "react-i18next";
import MenuTestMainPage from "../MenuTestMainPage/MenuTestMainPage";
import OurOffers from "../../Components/OurOffers/OurOffers";

function TestMainPage() {
  const { data, error, isLoading } = useGetAllCategoriesQuery();
  const { t } = useTranslation();
  const [mainPageImageUrl, setMainPageImageUrl] = useState("");

  // useEffect(() => {
  //   async function fetchMainPageImage() {
  //     try {
  //       const response = await axios.get(
  //         "https://svizhabe.herokuapp.com/assets/main-page-image/"
  //       );
  //       if (response.data && response.data.results.length > 0) {
  //         setMainPageImageUrl(response.data.results[0].image);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching main page image:", error);
  //     }
  //   }
  //   fetchMainPageImage();
  // }, []);

  return (
    <div>
      <main>
        {/* <ModalInfo /> */}
        <div className={main.maincontainer}>
          <div className={main.firstscreen}>
            <div className={main.left}>
              <h1 className={main.utp}>
                <span className={main.highlight}>{t("main.Ready")} </span>
                {t("main.fresh_frozen")} <br /> {t("main.meals")}
                <span className={main.highlight}>
                  {" "}
                  {t("main.for_every_day")}
                </span>
              </h1>
              <p className={main.utpdescription}>
                {t("main.Our_fresh_food_retains_all_the_nutrients")}
                <br className={main.mainbrs} />
                {t("main.and_taste_properties_through_technology")}
                <br className={main.mainbrs} /> {t("main.shock_freezing")}
              </p>
              <Link to={"/menu"} className={main.ctabutton}>
                {t("main.I_want_to_order")}
              </Link>
            </div>

            <img className={main.tray} src={dishdouble} alt="Упаковка" />
          </div>

          <OurOffers />

          <div className={main.Menu}>
            <MenuTestMainPage />
          </div>

          <div className={main.svizhanumbers}>
            <h2>{t("main.information")}</h2>
            <div className={main.svizhanumberscontent}>
              <div className={main.svizhanum}>
                <h3>5</h3>
                <p>{t("main.five_years")}</p>
              </div>
              <div className={main.svizhanum}>
                <h3>1500+</h3>
                <p>{t("main.satisfied")}</p>
              </div>

              <div className={main.svizhanum}>
                <h3>90000+</h3>
                <p>
                  {t("main.your")} <br /> {t("main.saved")}
                </p>
              </div>
              <div className={main.svizhanum}>
                <h3>100%</h3>
                <p>
                  {t("main.people")} <br /> {t("main.us")}
                </p>
              </div>
            </div>
          </div>

          <div className={main.categoriessection}>
            {isLoading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>An error occured</p>
            ) : (
              <div className={main.slidercategories}>
                {data.results.length > 0 && (
                  <div className={main.categoriessection}>
                    <h2>{data.results[0].title}</h2>
                    {data.results[0].categories.map((cat) => (
                      <SliderCategory
                        id={`#category${cat.category_id}`}
                        keyProp={`cat_${cat.category_id}`}
                        key={`cat_${cat.category_id}`}
                        category_name={cat.category_name}
                        catId={cat.category_id}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={main.svizhapartners}>
            <h2>{t("main.We_are_close")}</h2>
            <div className="partners-container">
              <a href="https://instagram.com/cinus_food?igshid=MzRlODBiNWFlZA==">
                <img src={partner1} alt="" />
              </a>
              <a href="https://mandryk.com.ua/">
                <img src={partner3} alt="" />
              </a>
            </div>
          </div>

          <div className={main.svizhacustomersreviews}>
            <h2 className={main.mainh2}>{t("main.Reviews")}</h2>
            <Swiper
              slidesPerView={0}
              spaceBetween={0}
              loop={true}
              freeMode={true}
              modules={[Autoplay]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 45,
                },

                1024: {
                  slidesPerView: 3,
                  spaceBetween: 75,
                },
                1440: {
                  slidesPerView: 3,
                  spaceBetween: 33,
                },

                1920: {
                  slidesPerView: 4,
                  spaceBetween: 33,
                },
              }}
              className={main.reviewsswiper}
            >
              <SwiperSlide>
                <div className={main.customerreview}>
                  <h3>Антоніна</h3>
                  <p>
                    Вечір добрий! <br />
                    Дякую вам за смакоту! <br />
                    Зробила сьогодні курча, навіть дитина Їсть. <br />
                    Це для нас прям прогрес.
                  </p>
                  <img className={main.reviewimage} src={review1} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className={main.customerreview}>
                  <h3>Руфат</h3>
                  <p>
                    Добрий день. <br />
                    У вас все файно! <br />
                    Іжа смачна. <br />
                    Сервіс відмінний! <br />
                    Продовжуйте в тому же дусі!
                  </p>
                  <img className={main.reviewimage} src={review2} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className={main.customerreview}>
                  <h3>Інна</h3>
                  <p>
                    Добрий день! <br />
                    Дякую за ваше фетучіні с кабачком <br />
                    та куркою! Страва чудова! Вона ніжна, приємна на смак та має
                    неперевершений аромат! <br />І дуже смачні голубці з
                    яловичиною.
                  </p>
                  <img className={main.reviewimage} src={review3} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className={main.customerreview}>
                  <h3>Аліє</h3>
                  <p>
                    Добрий вечір! Дякую вам за якісну та смачну продукцію,
                    спробувала сьогодні голубці-це кайф! <br /> І головне не
                    потрібно стояти біля плити. <br /> Завтра буду пробувати
                    крученики
                  </p>
                  <img className={main.reviewimage} src={review4} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className={main.customerreview}>
                  <h3>Алла</h3>
                  <p>
                    Вчора на вечерю їли голубці. <br />
                    Маленькі, охайні, ніжні. Вдало приправлені спеціями. <br />
                    Смачно! <br /> Дякуємо!
                  </p>
                  <img className={main.reviewimage} src={review5} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className={main.customerreview}>
                  <h3>Юлія</h3>
                  <p>
                    Моя дитина в захваті від ваших страв! Ви молодці!
                    Рекомендую!
                  </p>
                  <img className={main.reviewimage} src={review6} alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <h2 className={main.mainh2}>{t("main.often")}</h2>

          <FAQ />

          <div className={main.advantagescard}>
            <div className={main.advantagesheading}>
              <img
                src={logo}
                alt="Свїжа"
                className={main.advantagesheadinglogo}
              />
              <h3 className={main.advantagesheadingtext}>
                {" "}
                <span className={main.advlogomobile}>{t("main.Fresh")}</span>
                {"  "}
                {t("main.is_ready")}
              </h3>
            </div>

            <div className={main.advantagescomponentcontainer}>
              <div className={main.advantagescomponent}>
                <img src={smile} alt="smile" />
                <p>{t("main.We_love")}</p>
              </div>

              <div className={main.advantagescomponent}>
                <img src={heart} alt="heart" />
                <p>{t("main.That_why")}</p>
              </div>
            </div>
            <Link to={"/menu"}>{t("main.I_want_to_order")}</Link>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TestMainPage;
